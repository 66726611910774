.ui-select {
	@apply tw-relative tw-flex tw-flex-col;
}
.ui-select svg {
	@apply tw-flex-shrink-0 tw-flex-grow-0;
}

.ui-select .ui-select-button {
	@apply tw-flex tw-cursor-pointer tw-select-none tw-items-center tw-justify-between;
}

.ui-select:not(.ui-select-multiple) .ui-select-button .ui-select-button-options {
	@apply tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap;
}
.ui-select.ui-select-multiple .ui-select-button .ui-select-button-options {
	@apply tw-flex tw-flex-wrap;
}
.ui-select .ui-select-button-option {
	@apply tw-flex tw-items-center;
}
.ui-select .ui-select-button-option-remove {
	@apply tw-cursor-pointer;
}

.ui-select .ui-select-options {
	@apply tw-flex tw-flex-col;
}

.ui-select-options-search-empty {
	@apply tw-select-none;
}

.ui-select .ui-select-options-list {
	@apply tw-flex tw-flex-shrink tw-flex-grow tw-select-none tw-flex-col tw-overflow-hidden tw-overflow-y-auto;
}

.ui-select .ui-select-option {
	@apply tw-flex tw-cursor-pointer;
}
.ui-select .ui-select-option-active {
	@apply tw-cursor-default;
}
