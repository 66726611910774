.ui-message {
	@apply tw-flex tw-items-start;
}
.ui-message > svg {
	@apply tw-flex-shrink-0 tw-flex-grow-0;
}

.ui-message > .ui-message-body {
	@apply tw-flex tw-flex-shrink tw-flex-grow tw-flex-col;
}
