.ui-table {
	@apply tw-relative tw-min-w-full tw-overflow-x-auto tw-overflow-y-auto;
}
.ui-table th a {
	@apply tw-inline-flex tw-items-center;
}

.ui-table.ui-table-sticky thead {
	@apply tw-sticky tw-top-0 tw-z-[20];
}
