.ui-radio {
	@apply tw-inline-flex tw-select-none tw-self-start;
}
.ui-radio input[type='radio'] {
	@apply tw-hidden;
}
.ui-radio input[type='radio'] ~ * {
	@apply tw-cursor-pointer;
}
.ui-radio input[type='radio']:disabled ~ * {
	@apply tw-cursor-default;
}
.ui-radio .ui-radio-checkmark {
	@apply tw-flex-shrink-0 tw-flex-grow-0;
}
