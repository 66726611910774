.ui-tabs {
	@apply tw-flex tw-flex-col
}
.ui-tabs-buttons {
	@apply tw-flex tw-items-stretch;
}
.ui-tab {
	@apply tw-block tw-select-none;
}
.ui-tab-active {
	@apply tw-cursor-default;
}
