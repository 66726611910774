.ui-autocomplete {
	@apply tw-relative tw-flex tw-flex-col;
}
.ui-autocomplete svg {
	@apply tw-flex-shrink-0 tw-flex-grow-0;
}

.ui-autocomplete .ui-autocomplete-button {
	@apply tw-flex tw-flex-col;
}
.ui-autocomplete .ui-autocomplete-button-input {
	@apply tw-flex tw-items-center;
}
.ui-autocomplete label.ui-autocomplete-button-input,
.ui-autocomplete label.ui-autocomplete-button input {
	@apply tw-cursor-text disabled:tw-bg-transparent;
}
.ui-autocomplete .ui-autocomplete-button-input input {
	@apply tw-w-full tw-flex-shrink tw-flex-grow;
}

.ui-autocomplete .ui-autocomplete-selected {
	@apply tw-flex tw-flex-wrap;
}
.ui-autocomplete .ui-autocomplete-selected-item {
	@apply tw-flex tw-cursor-default tw-items-center;
}
.ui-autocomplete .ui-autocomplete-selected-item-remove {
	@apply tw-cursor-pointer;
}

.ui-autocomplete .ui-autocomplete-options {
	@apply tw-flex tw-flex-col;
}

.ui-autocomplete-options-search-empty {
	@apply tw-select-none;
}

.ui-autocomplete .ui-autocomplete-options-list {
	@apply tw-flex tw-flex-shrink tw-flex-grow tw-select-none tw-flex-col tw-overflow-hidden tw-overflow-y-auto;
}

.ui-autocomplete .ui-autocomplete-option {
	@apply tw-flex tw-cursor-pointer;
}
.ui-autocomplete .ui-autocomplete-option-active {
	@apply tw-cursor-default;
}
.ui-autocomplete-option-show-all-button {
	@apply tw-w-full tw-cursor-pointer;
}
