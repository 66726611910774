.ui-chart {
    @apply tw-w-full
}

.ui-chart .ct-label {
	@apply tw-leading-none tw-select-none
}

.ui-chart .ct-label-point {
    @apply tw-rounded-full tw-flex tw-items-center tw-justify-center tw-translate-x-[-50%] tw-translate-y-[-50%]
}

.ui-chart .ct-chart-bar .ct-label,
.ui-chart .ct-chart-line .ct-label {
	@apply tw-flex;
}

.ui-chart .ct-chart-donut .ct-label,
.ui-chart .ct-chart-pie .ct-label {
	dominant-baseline: central;
}

.ui-chart .ct-label.ct-horizontal {
    @apply tw-translate-x-[-50%] tw-text-center tw-justify-center;
    text-anchor: middle;
}
.ui-chart .ct-label.ct-horizontal-first {
    @apply tw-translate-x-[0] tw-text-left tw-justify-start;
    text-anchor: start;
}
.ui-chart .ct-label.ct-horizontal-last {
    @apply tw-translate-x-[-100%] tw-text-right tw-justify-end;
    text-anchor: end;
}
.ui-chart .ct-label.ct-vertical {
    @apply tw-translate-y-[50%] tw-items-center tw-text-right;
	text-anchor: middle;
}
.ui-chart .ct-label.ct-vertical-first {
    @apply tw-translate-y-[0] tw-items-end;
	text-anchor: end;
}
.ui-chart .ct-label.ct-vertical-last {
    @apply tw-translate-y-[50%] tw-items-start;
	text-anchor: start;
}


/*TODO: добавить выравнивание для первого и последнего горизонтального и вертикального лейбла*/


/* Мб эта херня больше не нужна, потестить */
/*
.ui-chart .ct-label.ct-horizontal.ct-start,
.ui-chart .ct-label.ct-vertical.ct-end,
.ui-chart .ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
    @apply tw-justify-start tw-items-end tw-text-left;
	text-anchor: start;
}

.ui-chart .ct-label.ct-horizontal.ct-end,
.ui-chart .ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
    @apply tw-justify-start tw-items-start tw-text-left;
	text-anchor: start;
}

.ui-chart .ct-label.ct-vertical.ct-start {
    @apply tw-justify-end tw-items-end tw-text-right;
	text-anchor: end;
}

.ui-chart .ct-chart-bar .ct-label.ct-horizontal.ct-start {
    @apply tw-justify-center tw-items-end tw-text-center;
	text-anchor: start;
}

.ui-chart .ct-chart-bar .ct-label.ct-horizontal.ct-end {
    @apply tw-justify-center tw-items-start tw-text-center;
	text-anchor: start;
}

.ui-chart .ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
    @apply tw-justify-end tw-items-center tw-text-right;
	text-anchor: end;
}

.ui-chart .ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
    @apply tw-justify-start tw-items-center tw-text-left;
	text-anchor: end;
}
*/

.ct-bar,
.ct-line,
.ct-slice-donut,
.ui-chart .ct-grid-background {
	fill: none;
}
.ct-point {
	stroke-linecap: round;
}
.ct-area {
	stroke: none;
}

.ui-chart .ct-square,
.ui-chart .ct-perfect-fourth,
.ui-chart .ct-perfect-fifth,
.ui-chart .ct-golden-section,
.ui-chart .ct-octave,
.ui-chart .ct-double-octave,
.ui-chart .ct-minor-second,
.ui-chart .ct-minor-third,
.ui-chart .ct-minor-sixth,
.ui-chart .ct-minor-seventh,
.ui-chart .ct-major-second,
.ui-chart .ct-major-third,
.ui-chart .ct-major-sixth,
.ui-chart .ct-major-seventh,
.ui-chart .ct-major-tenth,
.ui-chart .ct-major-eleventh,
.ui-chart .ct-major-twelfth {
    @apply tw-block tw-relative tw-w-full before:tw-block before:tw-float-left before:tw-w-0 before:tw-h-0 after:tw-table after:tw-clear-both
}

.ui-chart .ct-square {
    @apply before:tw-pb-[100%]
}
.ui-chart .ct-perfect-fourth {
    @apply before:tw-pb-[75%]
}
.ui-chart .ct-perfect-fifth {
    @apply before:tw-pb-[66.6666666667%]
}
.ui-chart .ct-golden-section {
    @apply before:tw-pb-[61.804697157%]
}
.ui-chart .ct-octave {
    @apply before:tw-pb-[50%]
}
.ui-chart .ct-double-octave {
    @apply before:tw-pb-[25%]
}
.ui-chart .ct-minor-second {
    @apply before:tw-pb-[93.75%]
}
.ui-chart .ct-minor-third {
    @apply before:tw-pb-[83.3333333333%]
}
.ui-chart .ct-minor-sixth {
    @apply before:tw-pb-[62.5%]
}
.ui-chart .ct-minor-seventh {
    @apply before:tw-pb-[56.25%]
}
.ui-chart .ct-major-second {
    @apply before:tw-pb-[88.8888888889%]
}
.ui-chart .ct-major-third {
    @apply before:tw-pb-[80%]
}
.ui-chart .ct-major-sixth {
    @apply before:tw-pb-[60%]
}
.ui-chart .ct-major-seventh {
    @apply before:tw-pb-[53.3333333333%]
}
.ui-chart .ct-major-tenth {
    @apply before:tw-pb-[40%]
}
.ui-chart .ct-major-eleventh {
    @apply before:tw-pl-[37.5%]
}
.ui-chart .ct-major-twelfth {
    @apply before:tw-pl-[33.3333333333%]
}

.ui-chart .ct-square > svg,
.ui-chart .ct-perfect-fourth > svg,
.ui-chart .ct-perfect-fifth > svg,
.ui-chart .ct-golden-section > svg,
.ui-chart .ct-octave > svg,
.ui-chart .ct-double-octave > svg,
.ui-chart .ct-minor-second > svg,
.ui-chart .ct-minor-third > svg,
.ui-chart .ct-minor-sixth > svg,
.ui-chart .ct-minor-seventh > svg,
.ui-chart .ct-major-second > svg,
.ui-chart .ct-major-third > svg,
.ui-chart .ct-major-sixth > svg,
.ui-chart .ct-major-seventh > svg,
.ui-chart .ct-major-tenth > svg,
.ui-chart .ct-major-eleventh > svg,
.ui-chart .ct-major-twelfth > svg {
    @apply tw-block tw-absolute tw-top-0 tw-left-0
}
