.ui-overlay {
	@apply tw-fixed tw-inset-0 tw-z-[60] tw-overflow-y-auto;
}
.ui-overlay-content {
	@apply tw-relative tw-flex tw-min-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center;
}
.ui-overlay-backdrop {
	@apply tw-absolute tw-inset-0 tw-z-[10];
}

.ui-overlay-unclickable .ui-overlay-backdrop {
	@apply tw-pointer-events-none;
}
