.ui-dropzone {
	@apply tw-flex tw-w-full tw-cursor-pointer tw-select-none tw-flex-col tw-justify-center tw-self-start tw-transition-colors tw-duration-200;
}
.ui-dropzone-disabled {
	@apply disabled:tw-cursor-not-allowed;
}
.ui-dropzone .ui-dropzone-files {
	@apply tw-grid tw-flex-shrink-0 tw-flex-grow;
}
.ui-dropzone .ui-dropzone-file {
	@apply tw-flex tw-flex-col tw-relative tw-bg-cover tw-bg-top;
}
.ui-dropzone .ui-dropzone-file-name {
	@apply tw-mt-auto tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap
}
.ui-dropzone .ui-dropzone-file-icon-wrapper {
	@apply tw-flex-grow tw-flex-shrink tw-flex tw-items-center tw-justify-center
}
.ui-dropzone .ui-dropzone-file-remove-button {
	@apply tw-absolute;
}
.ui-dropzone .ui-dropzone-file-remove-button svg {
	@apply tw-flex-shrink-0 tw-flex-grow-0;
}
