.ui-checkbox {
	@apply tw-inline-flex tw-select-none tw-self-start;
}
.ui-checkbox input[type='checkbox'] {
	@apply tw-hidden;
}
.ui-checkbox input[type='checkbox'] ~ * {
	@apply tw-cursor-pointer;
}
.ui-checkbox input[type='checkbox']:disabled ~ * {
	@apply tw-cursor-default;
}
.ui-checkbox .ui-checkbox-checkmark {
	@apply tw-flex-shrink-0 tw-flex-grow-0;
}
