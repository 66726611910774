.ui-arrow {
	@apply tw-relative before:tw-absolute before:tw-h-0 before:tw-w-0;
}
.ui-arrow.ui-arrow-top {
	@apply before:tw-bottom-full before:tw-left-1/2 before:tw--translate-x-1/2 before:!tw-border-t-0 before:!tw-border-x-transparent;
}
.ui-arrow.ui-arrow-top-left {
	@apply before:tw-bottom-full before:tw-left-0 before:!tw-border-t-0 before:!tw-border-x-transparent;
}
.ui-arrow.ui-arrow-top-right {
	@apply before:tw-bottom-full before:tw-right-0 before:!tw-border-t-0 before:!tw-border-x-transparent;
}
.ui-arrow.ui-arrow-left {
	@apply before:tw-right-full before:tw-top-1/2 before:tw--translate-y-1/2 before:!tw-border-l-0 before:!tw-border-y-transparent;
}
.ui-arrow.ui-arrow-right {
	@apply before:tw-left-full before:tw-top-1/2 before:tw--translate-y-1/2 before:!tw-border-r-0 before:!tw-border-y-transparent;
}
.ui-arrow.ui-arrow-bottom {
	@apply before:tw-left-1/2 before:tw-top-full before:tw--translate-x-1/2 before:!tw-border-b-0 before:!tw-border-x-transparent;
}
.ui-arrow.ui-arrow-bottom-left {
	@apply before:tw-left-0 before:tw-top-full before:tw-border-b-0 before:!tw-border-x-transparent;
}
.ui-arrow.ui-arrow-bottom-right {
	@apply before:tw-right-0 before:tw-top-full before:tw-border-b-0 before:!tw-border-x-transparent;
}
