.ui-input:not(input) {
	@apply tw-flex tw-items-center;
}
label.ui-input,
label.ui-input input {
	@apply tw-cursor-text disabled:tw-bg-transparent;
}

.ui-input:not(input) input,
input.ui-input {
	@apply tw-relative tw-w-full tw-flex-shrink tw-flex-grow tw-items-center tw-self-start;
}

label.ui-input input {
	@apply read-only:tw-cursor-default disabled:tw-cursor-not-allowed;
}
.ui-input.ui-input-disabled {
	@apply tw-cursor-not-allowed;
}
.ui-input.ui-input-read-only {
	@apply tw-cursor-default;
}

.ui-input-button-remove {
	@apply tw-flex-shrink-0 tw-flex-grow-0 tw-cursor-pointer;
}

.ui-input:not(input).ui-input-number {
	@apply tw-items-stretch;
}
.ui-input:not(input).ui-input-number input {
	-moz-appearance: textfield;
}
.ui-input:not(input).ui-input-number input::-webkit-outer-spin-button,
.ui-input:not(input).ui-input-number input::-webkit-inner-spin-button {
	@apply tw-m-0;
	-webkit-appearance: none;
}
.ui-input:not(input).ui-input-number .ui-input-number-button {
	@apply tw-flex-shrink-0 tw-flex-grow-0;
}

.ui-input:not(input).ui-input-password {
	@apply tw-items-stretch;
}
.ui-input:not(input).ui-input-password .ui-input-password-toggle {
	@apply tw-flex-shrink-0 tw-flex-grow-0;
}

.ui-input:not(input).ui-input-search {
	@apply tw-items-stretch;
}
.ui-input:not(input).ui-input-search .ui-input-search-button {
	@apply tw-flex-shrink-0 tw-flex-grow-0;
}
