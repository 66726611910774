.ui-sidebar {
	@apply tw-fixed tw-z-[50] tw-flex tw-transform tw-flex-col;
}

.ui-sidebar-left {
	@apply tw-inset-y-0 tw-right-full tw-max-w-full;
}
.ui-sidebar-left.ui-sidebar-opened {
	@apply tw-translate-x-full;
}
.ui-sidebar-right {
	@apply tw-inset-y-0 tw-left-full tw-max-w-full;
}
.ui-sidebar-right.ui-sidebar-opened {
	@apply tw--translate-x-full;
}
.ui-sidebar-top {
	@apply tw-inset-x-0 tw-bottom-full tw-max-h-full;
}
.ui-sidebar-top.ui-sidebar-opened {
	@apply tw-translate-y-full;
}
.ui-sidebar-bottom {
	@apply tw-inset-x-0 tw-top-full tw-max-h-full;
}
.ui-sidebar-bottom.ui-sidebar-opened {
	@apply tw--translate-y-full;
}

.ui-sidebar-button-close {
	@apply tw-flex-shrink-0 tw-flex-grow-0;
}
.ui-sidebar-header {
	@apply tw-flex tw-flex-shrink-0 tw-flex-grow-0 tw-justify-between;
}
.ui-sidebar-body {
	@apply tw-relative tw-flex-shrink tw-flex-grow;
}
.ui-sidebar-content {
	@apply tw-absolute tw-inset-0 tw-overflow-y-auto;
}
