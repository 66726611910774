label.ui-textarea,
label.ui-textarea textarea {
	@apply tw-cursor-text disabled:tw-bg-transparent;
}

.ui-textarea:not(textarea) textarea,
textarea.ui-textarea {
	@apply tw-relative tw-w-full tw-self-start;
}

label.ui-textarea textarea {
	@apply read-only:tw-cursor-default disabled:tw-cursor-not-allowed disabled:tw-bg-transparent;
}
.ui-textarea.ui-textarea-disabled {
	@apply tw-cursor-not-allowed;
}
.ui-textarea.ui-textarea-read-only {
	@apply tw-cursor-default;
}
