.ui-datepicker {
	@apply tw-flex tw-items-stretch;
}

.ui-datepicker .ui-input-date:not(input) input,
.ui-datepicker input.ui-input-date {
	@apply !tw-cursor-pointer read-only:!tw-text-inherit;
}

.ui-datepicker-panel {
	@apply tw-flex tw-flex-col tw-overflow-y-auto;
}
.ui-datepicker-panel-nav {
	@apply tw-flex tw-select-none tw-items-center tw-justify-between;
}
.ui-datepicker-panel-nav-button {
	@apply tw-flex tw-items-center tw-justify-center;
}
.ui-datepicker-calendar {
	@apply tw-grid tw-select-none tw-grid-cols-7;
}
.ui-datepicker-calendar-header {
	@apply tw-flex tw-items-center tw-justify-center;
}
.ui-datepicker-calendar-cell {
	@apply tw-flex tw-items-center tw-justify-center;
}
.ui-datepicker-calendar-button {
	@apply tw-flex tw-items-center tw-justify-center;
}
