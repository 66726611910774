.ui-text-editor .tox-tinymce {
	@apply !tw-h-full tw-border-none;
}

.ui-text-editor .tox .tox-tbtn {
	@apply tw-m-0 tw-mr-[8px] tw-h-[20px] tw-w-[20px] tw-cursor-pointer last:tw-mr-0 hover:tw-bg-transparent;
}

.ui-text-editor .tox .tox-tbtn--enabled {
	@apply tw-bg-transparent;
}

.ui-text-editor .tox .tox-tbtn:not(:last-child) > span {
	@apply tw-hidden;
}

.ui-text-editor .tox .tox-tbtn[aria-label='Bold'] {
	background-image: url('/assets/images/icons/text-editor/bold.svg');
}

.ui-text-editor .tox .tox-tbtn--enabled[aria-label='Bold'],
.ui-text-editor .tox .tox-tbtn:hover[aria-label='Bold'] {
	background-image: url('/assets/images/icons/text-editor/bold-active.svg');
}

.ui-text-editor .tox .tox-tbtn[aria-label='Italic'] {
	background-image: url('/assets/images/icons/text-editor/italic.svg');
}

.ui-text-editor .tox .tox-tbtn--enabled[aria-label='Italic'],
.ui-text-editor .tox .tox-tbtn:hover[aria-label='Italic'] {
	background-image: url('/assets/images/icons/text-editor/italic-active.svg');
}

.ui-text-editor .tox .tox-tbtn[aria-label='Underline'] {
	background-image: url('/assets/images/icons/text-editor/underline.svg');
}

.ui-text-editor .tox .tox-tbtn--enabled[aria-label='Underline'],
.ui-text-editor .tox .tox-tbtn:hover[aria-label='Underline'] {
	background-image: url('/assets/images/icons/text-editor/underline-active.svg');
}

.ui-text-editor .tox .tox-tbtn[aria-label='Numbered list'] {
	background-image: url('/assets/images/icons/text-editor/numbered-list.svg');
}

.ui-text-editor .tox .tox-tbtn--enabled[aria-label='Numbered list'],
.ui-text-editor .tox .tox-tbtn:hover[aria-label='Numbered list'] {
	background-image: url('/assets/images/icons/text-editor/numbered-list-active.svg');
}

.ui-text-editor .tox .tox-tbtn[aria-label='Bullet list'] {
	background-image: url('/assets/images/icons/text-editor/unnumbered-list.svg');
}

.ui-text-editor .tox .tox-tbtn--enabled[aria-label='Bullet list'],
.ui-text-editor .tox .tox-tbtn:hover[aria-label='Bullet list'] {
	background-image: url('/assets/images/icons/text-editor/unnumbered-list-active.svg');
}

.ui-text-editor .tox .tox-tbtn[aria-label='Insert/edit link'] {
	background-image: url('/assets/images/icons/text-editor/link.svg');
}

.ui-text-editor .tox .tox-tbtn--enabled[aria-label='Insert/edit link'],
.ui-text-editor .tox .tox-tbtn:hover[aria-label='Insert/edit link'] {
	background-image: url('/assets/images/icons/text-editor/link-active.svg');
}

.ui-text-editor .tox .tox-tbtn[aria-label='Blocks'] {
	@apply tw-w-auto;
}

.ui-text-editor .tox .tox-tbtn[aria-label='Blocks'] > span {
	@apply tw-block;
}

.ui-text-editor .tox .tox-toolbar__group {
	@apply tw-p-[8px];
}

.ui-text-editor .tox .tox-tbtn svg {
	@apply tw-fill-secondary-on-surface;
}

.ui-text-editor .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks):first-letter {
	@apply tw-bg-negative tw-text-primary;
}

.tox-menu h1 {
	font-size: 24px !important;
	line-height: 28px !important;
	font-weight: 700 !important;
}

.tox-menu h2 {
	font-size: 20px !important;
	line-height: 24px !important;
	font-weight: 700 !important;
}

.tox-menu h3 {
	font-size: 18px !important;
	line-height: 22px !important;
	font-weight: 700 !important;
}

.tox-menu p {
	font-size: 16px !important;
	line-height: 22px !important;
	font-weight: 400 !important;
}
